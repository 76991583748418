/* SearchBar.css */

.search-bar-container {
    display: flex;
    /* Utiliser flexbox pour aligner les enfants */
    justify-content: space-evenly;
    /* Centrer horizontalement */
    align-items: center;
    /* Centrer verticalement */
    width: 100vw;
    padding-top: 25px;
    padding-bottom: 15px;
    margin: auto;
    margin-bottom: 25px;
}

.menu-icon,
.site-icon,
.menu-btn {
    margin: auto;
    display: flex; /* Ajout de la propriété display avec la valeur flex */
    align-items: center; /* Centrer verticalement */
}

.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    width: 285px;
}

.search-icon {
    margin-right: 10px;
    margin-left: 5px;
}

.search-input {
    border: none;
    outline: none;
    flex: 1;
    border-radius: 20px;
    padding: 7px 5px;
    font-size: 13px;
    color: #4C4B4B;
}

/* Styles pour le bouton avec trois petits traits */
.menu-btn {
    width: 28px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.menu-icon,
.site-icon,
.menu-btn {
    margin: 0 auto; /* Pour centrer horizontalement */
    display: flex; /* Utilisation de flexbox */
    justify-content: center; /* Centrer horizontalement */
    align-items: center; /* Centrer verticalement */
}

.menu-btn-burger,
.menu-btn-burger::before,
.menu-btn-burger::after {
    content: '';
    display: block;
    width: 100%;
    height: .24em;
    background-color: #F7253F;
    position: absolute;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.menu-btn-burger::before {
    top: -9px;
}

.menu-btn-burger::after {
    bottom: -9px;
}

/* Styles pour l'image de l'icône du site */
.icon-image {
    width: 25px;
    height: 25px;
}
