.all-mangas-container {
    width: 90vw;
    margin: auto;
    padding: 20px 0;
}

.all-mangas-header {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.all-mangas-title {
    color: #FFF;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
}

.back-button {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.all-mangas-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc((90vw - 40px) / 3), 1fr));
    gap: 15px;
    justify-content: center;
}

.all-mangas-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e1e1e;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
}

.all-mangas-item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.all-mangas-item-image {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 15%;
    object-fit: cover;
}
.title-and-chapterCount{
    width: 5rem;
}
.all-mangas-item-title {
    color: #FFF;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 5px;
}

.all-mangas-item-chapters {
    color: #AAA;
    font-size: 12px;

    font-weight: bold;
    text-align: left;
    margin: 0;
}
