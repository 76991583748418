/* Ajoutez ces styles à votre fichier Manga_Page.css */

.catalogue-content {
    text-align: center; /* Centrage horizontal du contenu */
    color: white; /* Couleur du texte en blanc */
    margin:0 ;
    background-color: #0C0C0C;
    height: auto;
}

.catalogue-content h3 {
    color: white; /* Couleur du titre en blanc */
}

.bg-content {
    background-color: #181818;
    border-top: 1px solid #949494;
    padding: 5px; /* Ajustez la valeur du padding selon vos besoins */
    margin-top: 20px; /* Ajustez la valeur du margin-top selon vos besoins */
    overflow-y: auto;
}

.bg-content-pc {
    height: auto;
    border-top: 0px solid #949494;
    padding: 0 5vw; /* 0 en haut et en bas, 25vw à droite et à gauche */    margin-top: 20px; /* Ajustez la valeur du margin-top selon vos besoins */
    overflow-y: auto;
}

.profil-manga-container{
    width: 90vw;
}

.text-profil{
    color: #FFFFFF; /* Couleur du texte */
    opacity: .6;
    font-size: 10px;
    line-height: 2em;
    margin: 0 20px;
    margin-bottom: 15px;
    font-weight: 300;
}

/* Ligne rouge */
.red-divider {
    border: none;
    border-top: 3px solid red; /* Ligne rouge */
    margin: 30px auto; /* Marge top et bottom de 20px, auto pour centrage horizontal */
    width: 90%; /* Largeur de la ligne */
}

.p-noCache{
    font-size: 13px;
    opacity: .6;
}

.genres-container {
    display: flex;
    flex-direction: row; /* Affichage en lignes */
    flex-wrap: wrap;
    justify-content: start; /* Alignement horizontal */
    align-items: flex-start; /* Alignement vertical */
    background-color: #2C2C2C;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 5px;
    margin: 1rem;
    margin-bottom: 1.5rem;
}

.genres-container-pc {
    display: flex;
    flex-direction: row; /* Affichage en lignes */
    flex-wrap: wrap;
    justify-content: start; /* Alignement horizontal */
    align-items: flex-start; /* Alignement vertical */
    background-color: #2C2C2C;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 5px;
    margin: 0 18vw;
    margin-bottom: 1.5rem;
}

.titre-pc{
    margin-top: 12vh;
    margin-bottom: 2vh;
    font-size: 25px;
}

.genre-item {
    width: calc(33.33% - 20px); /* Pour trois éléments par ligne avec 20px de marge */
    margin-left: 15px;
    margin-bottom: 20px; /* Espacement entre les lignes */
    display: flex;
    align-items: center; /* Alignement vertical */
    font-size: 13px;
}

.genre-item-pc {
    width: calc(20.33% - 20px); /* Pour trois éléments par ligne avec 20px de marge */
    margin-left: 15px;
    margin-bottom: 20px; /* Espacement entre les lignes */
    display: flex;
    align-items: center; /* Alignement vertical */
    font-size: 13px;
}

.genre-item-pc svg {
    width: 16px; /* Ajustez la taille des icônes */
    height: 16px;
    margin-right: 5px; /* Marge à droite de l'icône */
}


.genre-item svg {
    width: 16px; /* Ajustez la taille des icônes */
    height: 16px;
    margin-right: 5px; /* Marge à droite de l'icône */
}

.circle-selected {
    color: #F7253F;
}


.circle-not-selected {
    color: white;
}

.gallery-container {
    color: #fff;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
}

.gallery-container-pc {
    margin-left: auto;
    margin-right: auto;
    width: 80vw;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Trois éléments par ligne avec une largeur minimale de 100px */
    gap: 10px; /* Espacement entre les éléments */
}


.gallery-item {
    padding: 8px;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0; /* Commencez par rendre les éléments transparents */
    animation: fade-in 0.8s ease-in-out forwards;
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.item-image-search {
    width: 100%;
    height: auto; /* Pour maintenir le ratio d'aspect de l'image */
    object-fit: cover;
    border-radius: 10px;
}

/* Style pour le conteneur de texte de l'élément */
.item-text {
    padding: 8px;
}

/* Style pour le compteur de résultats */
/* Style pour le compteur de résultats */
.gallery-result-count {
    font-size: 24px; /* Taille de la police */
    font-weight: 500; /* Gras */
    color: white; /* Couleur du texte */
    margin-left: 20px; /* Marge à gauche */
    margin-bottom: 1rem; /* Marge en haut */
    text-align: left;
}

/* Style pour le titre de l'élément */
.item-text-1 {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}

/* Style pour l'information de l'élément */
.item-text-2 {
    font-size: 12px;
    color: #777777;
    text-align: left;
}

.item-text-3 {
    color: #bdbdbd;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Media query pour la responsivité */
@media (max-width: 768px) {
    .gallery-container {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Trois éléments par ligne pour les écrans plus petits */
    }
}
