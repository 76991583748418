/* Ajoutez ces styles à votre fichier Manga_Page.css */

.profil-content {
    text-align: center; /* Centrage horizontal du contenu */
    color: white; /* Couleur du texte en blanc */
    margin:0 ;
    background-color: #0C0C0C;
}

.profil-content h3 {
    color: white; /* Couleur du titre en blanc */
}

.bg-content {
    background-color: #181818;
    border-top: 1px solid #949494;
    padding: 30px; /* Ajustez la valeur du padding selon vos besoins */
    margin-top: 20px; /* Ajustez la valeur du margin-top selon vos besoins */

    height: calc(100vh - 50px - 100px);

}

.profil-manga-container{
    width: 90vw;
}

.text-profil{
    color: #FFFFFF; /* Couleur du texte */
    opacity: .6;
    font-size: 10px;
    line-height: 2em;
    margin: 0 20px;
    margin-bottom: 15px;
    font-weight: 300;
}

/* Ligne rouge */
.red-divider {
    border: none;
    border-top: 3px solid red; /* Ligne rouge */
    margin: 30px auto; /* Marge top et bottom de 20px, auto pour centrage horizontal */
    width: 90%; /* Largeur de la ligne */
}

.p-noCache{
    font-size: 13px;
    opacity: .6;
}