.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 70vw;
    height: 100%;
    background-color: #151515;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 99;
    color: white;
    outline: none !important;
}

.side-menu.open {
    transform: translateX(0);
}

/* CSS pour l'overlay */
/* CSS pour l'overlay */
/* CSS pour l'overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: 98;
    opacity: 0;
    transition: opacity 0.3s ease; /* Transition de l'opacité */
    pointer-events: none; /* Par défaut, l'overlay ne doit pas être cliquable */
}

.overlay.open {
    opacity: 0.5;
    pointer-events: auto; /* Activer les événements de pointer lorsque l'overlay est ouvert */
}

.menu-line {
    display: flex;
    align-items: center;
    padding: 25px;
}

.menu-line img {
    width:70%;
    height: auto;
    margin-right: auto;
}

.menu-line span {
    font-size: 20px;
    cursor: pointer; /* Ajout d'un curseur pointer par défaut */
}

.menu-line span:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Couleur de fond lorsqu'il est survolé */
}

.menu-divider {
    border: none;
    border-top: 4px solid #ccc; /* Trait blanc */
    margin: 0 8%;
    border-radius: 15px;
}

.menu-line-divider {
    border: none;
    border-top: 2px solid #f7253f; /* Ligne rouge */
    margin: 0 8%;
    border-radius: 15px;
}

.menu-line button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 24px;
    position: relative;
    width: 40px;
    height: 40px;
    touch-action: none; /* Empêcher le défilement lors du toucher */
    user-select: none; /* Empêcher la sélection de texte */
    outline: none;
}

.menu-line button::before,
.menu-line button::after {
    content: ''; /* Contenu vide */
    position: absolute; /* Position absolue pour les éléments pseudo ::before et ::after */
    top: 50%; /* Alignement au centre vertical */
    left: 50%; /* Alignement au centre horizontal */
    width: 65%; /* Largeur des lignes */
    height: 2px; /* Épaisseur des lignes */
    background-color: white; /* Couleur des lignes */
    transform-origin: center; /* Point d'origine de la transformation */
    outline: none !important;
}

.menu-line button::before {
    transform: translate(-50%, -50%) rotate(45deg); /* Rotation de 45 degrés */
}

.menu-line button::after {
    transform: translate(-50%, -50%) rotate(-45deg); /* Rotation de -45 degrés */
}
