

.manga-reader-container {
    display: flex; /* Active Flexbox */
    flex-direction: column; /* Empile les enfants verticalement */
    justify-content: center; /* Centre les enfants verticalement dans le conteneur */
    align-items: center; /* Centre les enfants horizontalement dans le conteneur */
    height: 100vh; /* Prend toute la hauteur de la fenêtre d'affichage */
    width: 100vw; /* Prend toute la largeur de la fenêtre d'affichage */
    text-align: center; /* Assure que le texte à l'intérieur est également centré */
    background: white;
}

.manga-reader-container-pc {
    display: flex; /* Active Flexbox */
    flex-direction: column; /* Empile les enfants verticalement */
    justify-content: center; /* Centre les enfants verticalement dans le conteneur */
    align-items: center; /* Centre les enfants horizontalement dans le conteneur */
    height: 100vh; /* Prend toute la hauteur de la fenêtre d'affichage */
    width: 50vw; /* Prend toute la largeur de la fenêtre d'affichage */
    text-align: center; /* Assure que le texte à l'intérieur est également centré */
    background: white;
}

.reader-page{
    display: flex;
    justify-content: center; /* Centre les enfants verticalement dans le conteneur */
    align-items: center;
    height: 100vh; /* Prend toute la hauteur de la fenêtre d'affichage */
    width: 100vw; /* Prend toute la largeur de la fenêtre d'affichage */
}

.loading-spinner{
    display: flex; /* Utiliser un affichage en ligne */
    justify-content: center; /* Centrer horizontalement */
    align-items: center; /* Centrer verticalement */
    flex-direction: column;
    scale: .8;
}
.image-transition {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.app-bar {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #000;
    color: white;
    padding: 5px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.hidden {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.app-bar, .bottom-bar {
    transition: opacity 0.2s ease;
}


.vertical-scroll-container {

    position: sticky;
    /* Ajustez cette valeur en fonction de la hauteur de votre app-bar */
    overflow-y: auto;
}

.app-bar-spacer {
    width: 30px;
    height: 30px;
}

.back-button-reader{
    margin-right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    scale: 1.3;
}

.settings-icon {
    cursor: pointer;
    font-size: 28px;
    padding-top: 5px;
    padding-right: 25px;
}

.manga-reader-container h1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.manga-reader-container-pc h1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.manga-reader-image {
    width: 100%;
    height: auto;
    display: block;
}


.selected-chapter {
    background-color: #cccccc; /* Changez cette couleur selon vos besoins */
    font-weight: bold;
}



.lazyload-image {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    /* Autres styles spécifiques */
}

.manga-reader-controls {
    width: 100%;
    display: flex;
    justify-content: center;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Assurez-vous que la hauteur est définie pour permettre le centrage vertical */
}

.manga-reader-image {

    overflow-y: hidden;
    /* Permet de limiter la hauteur de l'image et éviter le débordement */
    object-fit: contain;
    /* Garde le ratio de l'image sans la déformer */
}


button:disabled {
    background-color: #cccccc;
    cursor: default;
}


.bottom-bar {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: #000;
    padding: 15px 0;
    color: white;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.bottom-bar span {
    font-size: 16px;
    font-weight: 500;
}

.bottom-bar svg {
    cursor: pointer;
    font-size: 24px;
}

/* Styles pour les boutons désactivés */
.bottom-bar svg[disabled] {
    color: #cccccc;
    cursor: default;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

/* Ajoutez ce code CSS à votre fichier CSS */

/* Ajoutez ce code CSS à votre fichier CSS */

.dropdown-menu {
    position: absolute;
    top: 48px;
    /* Ajustez la distance par rapport au logo "Settings" en fonction de votre design */
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    border-radius: 10px;
}

.dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
}

.chapter_name {
    margin: auto auto;
}

/* Container pour aligner le texte et le toggle */
.toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

/* Style pour le toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Forme arrondie des sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/* Style ajusté pour le toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    /* Vous pouvez ajuster la largeur selon vos besoins */
    height: 34px;
    /* Vous pouvez ajuster la hauteur selon vos besoins */
}

/* Curseur du slider */
.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    /* Taille plus grande pour le curseur */
    width: 30px;
    /* Taille plus grande pour le curseur */
    left: 2px;
    /* Ajustez en fonction de la nouvelle taille pour centrer */
    bottom: 2px;
    /* Ajustez en fonction de la nouvelle taille pour centrer */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    /* Garder les coins arrondis */
}

/* Fond du slider quand activé */
input:checked+.slider {
    background-color: #000;
    /* Couleur noire pour la sélection */
}

/* Augmenter la taille du slider pour le rendre plus visible */
.slider.round {
    border-radius: 40px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Cache le bouton radio d'origine dans le groupe spécifié */
.radio-group-custom input[type="radio"] {
    display: none;
}

/* Style de base pour les labels dans le groupe spécifié */
.radio-group-custom label {
    position: relative;
    padding-left: 35px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
}

/* Ajoute un cercle gris pour simuler un bouton radio non sélectionné dans le groupe spécifié */
.radio-group-custom label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ccc;
}

/* Lorsqu'un bouton radio est sélectionné, change le cercle en noir dans le groupe spécifié */
.radio-group-custom input[type="radio"]:checked+label:before {
    background: #000;
}


@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}

.container-reader-pc {
    position: absolute;
    bottom: 20px;
    right: 2.5vw;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.container-reader-pc.expanded {
    animation: slideUp 0.5s ease-out forwards;
}

.container-reader-pc:not(.expanded) {
    animation: slideDown 0.5s ease-out forwards;
}

.container-reader-pc .expand-button-pc-reader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translatey(10%); /* Centrer horizontalement */
    z-index: 1; /* Assurez-vous qu'il est au-dessus de l'image */
}

.banner-reader-pc {
    position: relative;
    border-top-left-radius: 15px; /* Arrondir le coin supérieur gauche */
    border-top-right-radius: 15px; /* Arrondir le coin supérieur droit */
    overflow: hidden; /* Assurez-vous que l'image ne dépasse pas les bords arrondis */
}

.img-container-reader-pc {
    width: 100%;
    height: auto;
    transition: opacity 2s linear; /* Ajoutez cette ligne */
    /* Add styles to ensure the image takes up the full width */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.img-scan-reader-pc.expanded {
    animation: fadeIn 1s linear forwards;
}

.img-scan-reader-pc:not(.expanded) {
    animation: fadeOut 1s linear forwards;
}

.img-scan-reader-pc {
    width: 100%;
    height: auto;
}

.content-reader-pc {
}

.top-row-reader-pc,
.bottom-row-reader-pc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.top-row-reader-pc{
    padding-top: 0;
}

.chapter-name-reader-pc {
    margin: 0;
    font-size: 1.2rem;
}

.btn-reader-pc {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.falist-btn-reader-pc {
    margin-right: 10px;
}

.home-btn-reader-pc {
    margin: 0 20px;
}

.description-text-pc-reader{
    font-size: 13px;
    padding: 0 25px ;
    margin: 0;
    margin-bottom: 20px;
}

.prev-container-reader-pc,
.next-container-reader-pc {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chevronleft-btn-reader-pc,
.chevronright-btn-reader-pc {
    margin: 0 5px;
}

.disabled-reader-pc {
    opacity: 0.5;
    pointer-events: none;
}

.prev-text-reader-pc,
.next-text-reader-pc {
    font-size: 1rem;
}

.spacer-reader-pc {
    flex-grow: 1;
}

.chapter_list-pc-reader {
    font-weight: normal;
    width: auto; /* Définir la largeur fixe */
    height: 20vh; /* Définir la hauteur fixe */
    overflow-y: auto; /* Activer le défilement vertical */
    border: 1px solid #ccc; /* Ajouter une bordure pour la visibilité */
    border-radius: 8px; /* Arrondir les coins */
    padding: 10px; /* Ajouter un peu de padding à l'intérieur */
    background-color: #fff; /* Fond blanc pour la lisibilité */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajouter une ombre pour le style */
    font-size: 14px;
}

.chapter_list-pc-reader ul {
    list-style: none; /* Supprimer les puces de la liste */
    padding: 0; /* Supprimer le padding par défaut */
    margin: 0; /* Supprimer la marge par défaut */
}

.chapter_list-pc-reader li {
    display: flex; /* Utiliser flex pour aligner les éléments */
    justify-content: space-between; /* Espacer le titre et le numéro du chapitre */
    padding: 8px; /* Ajouter du padding autour des éléments de la liste */
    border-bottom: 1px solid #eee; /* Ajouter une bordure inférieure pour séparer les éléments */
    cursor: pointer; /* Changer le curseur pour indiquer que les éléments sont cliquables */
}

.chapter_list-pc-reader li:hover {
    background-color: #f9f9f9; /* Changer la couleur de fond au survol pour l'interaction */
}

.chapter-title-pc-reader {
    font-weight: bold; /* Mettre en gras le titre des chapitres */
    margin-right: 10px; /* Ajouter un espacement entre le titre et le numéro */
}


