input[type="radio"] {
    display: none;
}

.card {
    position: absolute;
    width: 60vh;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.4s ease;
    cursor: pointer;
    color: white;
    border-radius: 15px;
}

.app-container-pc {
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
}

.container-pc {
    width: 100vw;
    max-width: 800px;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    height: 44vh;

}

.cards {
    position: relative;
    margin-bottom: 20px;
    height: auto;
}

.card-img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

}

.info-reprise-pc {
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */
    padding: 9px 15px; /* Espacement intérieur */
    border-bottom-right-radius: 10px; /* Coins arrondis */
}

.lecture-btn{
    margin-top: 15px;
}


.bottom-card{
    padding: 20px;
    padding-top: 10px;
}

.title-a-propos{
    margin-top: 0 !important;
}



#item-1:checked ~ .cards #song-1,
#item-2:checked ~ .cards #song-2,
#item-3:checked ~ .cards #song-3 {
    transform: translateX(0) scale(1);
    opacity: 1;
    z-index: 1;
}

#item-1:checked ~ .cards #song-2,
#item-2:checked ~ .cards #song-3,
#item-3:checked ~ .cards #song-1 {
    transform: translateY(-10%) translateX(60%) scale(0.8);
    opacity: 0.4;
    z-index: 0;
}

#item-1:checked ~ .cards #song-3,
#item-2:checked ~ .cards #song-1,
#item-3:checked ~ .cards #song-2 {
    transform: translateY(-10%) translateX(-50%) scale(0.8);
    opacity: 0.4;
    z-index: 0;
}

.player {
    background-color: #fff;
    border-radius: 8px;
    min-width: 320px;
    padding: 16px 10px;
}

.upper-part {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    height: 36px;
    overflow: hidden;
}

.play-icon {
    margin-right: 10px;
}

.song-info {
    width: calc(100% - 32px);
    display: block;
}

.song-info .title {
    color: #403d40;
    font-size: 14px;
    line-height: 24px;
}

.sub-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.subtitle,
.time {
    font-size: 12px;
    line-height: 16px;
    color: #c6c5c6;
}

.time {
    font-size: 12px;
    line-height: 16px;
    color: #a5a5a5;
    font-weight: 500;
    margin-left: auto;
}

.progress-bar {
    height: 3px;
    width: 100%;
    background-color: #e9efff;
    border-radius: 2px;
    overflow: hidden;
}

.progress {
    display: block;
    position: relative;
    width: 60%;
    height: 100%;
    background-color: #2992dc;
    border-radius: 6px;
}

.info-area {
    width: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transition: transform 0.4s ease-in;
}

#item-2:checked ~ .player #test {
    transform: translateY(0);
}

#item-2:checked ~ .player #test {
    transform: translateY(-40px);
}

#item-3:checked ~ .player #test {
    transform: translateY(-80px);
}


