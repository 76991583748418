.all-slide {
    position: fixed;
    top: 0;
    right: 0;
    height: 32.2vh;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 99;
    opacity: 1;
    display: flex;
    background-color: transparent;
}

.all-slide.open {
    transform: translateX(0);
}

.slide-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: transparent;
    opacity: 1;
}

.slide {
    background: #0C0C0C;
    padding: 20px;
    padding-top: 3px;

    z-index: 9999;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    width: 30vw;
}

.slide-fondu {
    margin-right: -2px;
    width: 15vw; /* Largeur totale de l'écran */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #0C0C0C); /* Dégradé transparent vers la gauche */
    z-index: 99999999;
}


.slide.active {
    opacity: 1;
    pointer-events: auto;
}

.slide div {
    margin-bottom: 20px;
}

.slide img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.slide p {
    margin-top: 5px;
}

.title-lire-aussi{
    color: white !important;
    text-align: center;
    padding-bottom: 2px;
}

.manga-recommanded-item {
    display: flex;
    justify-content: center;
}

.item-image {
    max-width: 100%; /* Assurez-vous que l'image ne dépasse pas la largeur du conteneur */
    height: auto; /* Garantit que l'image conserve ses proportions */
    border-radius: 50px;
}
