/* Styles supplémentaires pour swiper si nécessaire */
.swiper-mangapopulaire {
    width: 100vw;
    margin-bottom: 10.5vh;
    margin-left:1.80rem;
    overflow: visible;
}

.item-image.progressive-appear {
    opacity: 0; /* Commencez par rendre l'image transparente */
    animation: fade-in 0.8s ease-in-out forwards; /* Ajoutez une transition pour une apparition en douceur */
}

.slide-mobile-mangapopulaire.progressive-appear2{
    opacity: 0; /* Commencez par rendre les éléments transparents */
    animation: fade-in 0.8s ease-in-out forwards;
}



.item-title-mobile.progressive-appear,
.item-chapters-mobile.progressive-appear {
    opacity: 0; /* Commencez par rendre les éléments transparents */
    animation: fade-in 0.8s ease-in-out forwards; /* Ajoutez une transition pour une apparition en douceur */
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.HSpace{
    height: 1vh;
}
.swiper-slide-mangapopulaire {
    position: relative;
    /* Needed for absolute positioning of content */
    text-align: center;
    font-size: 14px;
}

.text-collection-manga-Populaire {
    color: #FFF;
    font-weight: 500;
    font-size: 18px;
    /* margin-left: 2.2rem;*/
    margin-left: 2.5rem;
    margin-bottom: 1.25rem;
}

.bg-img-carousel{
    background-color: #181818;
    position: absolute;
    width: 47vw;
    height: 14.5vh;
    border-radius: 8px;
    z-index: -1;
    animation: fadeInOut 3s infinite alternate;
}

.bg-img-icon-carousel{
    background-color: #181818;
    width: 40px;
    height: auto;
    z-index: -1;
    aspect-ratio: 1 / 1;
    border-radius: 25%;
    object-fit: cover;
    margin-right: 10px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
    animation: fadeInOut 3s infinite alternate;
}

.hide-background {
    background-color: transparent;
    animation: none !important;
}


@keyframes fadeInOut {
    0% {
        background-color: #181818; /* Couleur de fond initiale */
    }
    50% {
        background-color: #AAAAAA; /* Couleur de fond plus claire */
    }
    100% {
        background-color: #181818; /* Couleur de fond initiale */
    }
}

.slide-mobile-mangapopulaire {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 47vw;
    height: 15vh;
    border-radius: 8px;
}

.slide-mobile-mangapopulaire::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    border-radius: 8px;
}

.slide-content-mobile-mangapopulaire {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

/* Container pour le texte et l'icône */
.slide-container-mobile-mangapopulaire {
    align-self: start;
    text-align: left;
    margin-bottom: 40px;
}

/* Styles pour l'icône de l'application */
.icon-app-mobile-mangapopulaire {
    width: 50px;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 25%;
    object-fit: cover;
    transform: translateY(-40px);
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
}

/* Styles pour le texte au-dessus de l'icône */
.slide-text-above-mobile-mangapopulaire {
    font-size: 12px;
    /* Smaller text for mobile */
    color: #FFF;
    /* White text color */
    margin-bottom: 2px;
    /* Small space below text */
}

/* Styles pour le texte principal */
.slide-text-mobile-mangapopulaire {
    font-size: 10px;
    color: #FFF;
}

.download-button-mobile-mangapopulaire {
    position: absolute;
    bottom: 8px;
    right: 15px;
    padding: 10px 25px;
    background-color: rgba(255, 215, 215, 0.3);
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
}

.carousel-wrapper-mangapopulaire {
    position: relative;
}

.swiper-container-mangapopulaire {
    position: relative;
}

.indicators-mangapopulaire {
    display: flex;
    justify-content: center;
}

.indicator-mangapopulaire {
    width: 20px;
    height: 4px;
    background-color: #fff;
    margin: 0 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

.indicator-mangapopulaire.active {
    width: 30px;
    background-color: red;
}


.item-all-app-mobile-mangaPopulaire {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 16vh;
    overflow: visible;
}

.item-image-all-gallery-mobile {
    width: 40px;
    height: auto;
    border-radius: 25%;
}

.manga-details {
    display: flex;
    flex-direction: column;
}

.item-title-mobile {
    font-weight: bold;
    margin: 0;
    font-size: 12px;
}

.item-chapters-mobile {
    margin-top: 3px;
    font-size: 10px;
    font-weight: normal;
}
