/* Manga_Page.css */

/* General styling for the main container */

body {
    margin: 0;
    height: 100vh;
    background-color: #0C0C0C;
}t

.manga_body {
    position: relative;
}

.expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    scale: 2;
    padding: 0px 0;
    padding-bottom: 6px;
}

/* Hide scrollbar for webkit browsers (such as Chrome, Safari) */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
html {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Manga_Page {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 0px;
    max-width: 800px;
    margin: auto;
    color: #F8F8F8;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mosaic-container{
    margin: auto;
    font-weight: bold;
    scale: .8;
}
.manga_body {
    margin: 0px 25px;
    margin-bottom: 12px;
    flex-grow: 1;
    color: #cdcdcd;
    display: flex;
}

/* Styling for headers */
.Manga_Page h2,
.Manga_Page h3 {
    color: #444;
    margin-bottom: 10px;
}

/* Styling for image */
.Manga_Page img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0;
}

.img-scan{

    height: 32.2vh !important;
    width: 100vw;
}

/* Styling for paragraphs */
.Manga_Page p {
    line-height: 1.2;
    margin-bottom: 0px;
}

/* Styling for the chapters list */
.Manga_Page ul {
    list-style-type: none;
    padding: 0;
}

.Manga_Page ul li {
    background-color: #f0f0f0;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
}

/* Styling for error message */
.Manga_Page .error {
    color: red;
    font-weight: bold;
}.expandable-text {
     transition: max-height 0.8s ease;
     overflow: hidden;
     width: 74vw;
     position: relative; /* Ajout d'une position relative pour positionner le dégradé */
 }

.expandable-text::after {
    content: ""; /* Ajout d'un contenu pseudo-élément */
    position: absolute; /* Position absolue par rapport à l'élément parent */
    bottom: 0; /* Alignement en bas */
    left: 0; /* Alignement à gauche */
    width: 100%; /* Largeur pleine de l'élément parent */
    height: 55px; /* Hauteur du dégradé */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0C0C0C); /* Dégradé noir transparent vers opaque */
    transition: opacity 0.8s ease; /* Transition pour l'opacité */
    opacity: 1; /* Opacité par défaut */
}


.expandable-text.expanded::after {
    opacity: 0; /* Opacité à zéro lorsque l'élément est étendu */
}

/* Scrollable div style */
.Manga_Page .scrollable-div {
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
}

.chapter_list {
    flex-grow: 12;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chapter_list ul li {
    background-color: #181818;
    padding: 18px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 0;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    border-top: 1px solid #868686;
}

.chapter_list ul li.visited {
    background-color: black;
    opacity: 0.6; /* Réduit l'opacité pour griser les éléments */
    color: white;
    cursor: default; /* Définit le curseur par défaut */
}

.chapter_list ul li span {
    font-weight: 550;
    font-size: 15px;
}

.chapter-title{
    margin-left: .7rem;
}

.chapter_list ul li span:last-child {
    color: #666;
    font-style: italic;
}

.control_buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 5px;
}

.control_buttons button {
    padding-right: 12px;
    padding-right: 0px;
    font-size: 14px;
    font-weight: bold;
    min-width: 150px;
    background-color: #B23202;
    color: #fff;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.order_by img {
    max-width: 300px;
    height: 32px;
}

.banner {
    position: relative;
}

.overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 25px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.chapter_list::-webkit-scrollbar {
    width: 12px;
}

.chapter_list::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
}

.chapter_list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f0f0f0;
}

.chapter_list::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

.chapter_list::-webkit-scrollbar {
    width: 8px;
}

.chapter_list::-webkit-scrollbar-track {
    background: #000;
    border-radius: 10px;
}

.chapter_list::-webkit-scrollbar-thumb {
    background-color: #f0f0f0;
    border-radius: 10px;
    border: 3px solid #f0f0f0;
}

.chapter_list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.top-bar-right {
    position: fixed;
    padding: 18px 25px;
    top: 0;
    left: 0;
    font-size: 30px;
    z-index: 99999999;
}

.top-bar-left {
    position: fixed;
    padding: 17px 25px;
    top: 0;
    right: 0;
    font-size: 31px;
    z-index: 99999999;
    transition: transform 0.3s ease; /* Ajoutez une transition pour une animation fluide */
    display: flex;
    justify-content: center; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
    flex-direction: column;
}

.shifted {
    transform: translateX(-38vw); /* Décalage de 30vw vers la gauche */
}

.icon-shadow {
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 1));
}
.icon-shadow2 {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5));
}
@keyframes boing {
    0% { transform: scale(1); }
    20% { transform: scale(1.1); }
    40% { transform: scale(0.9); }
    60% { transform: scale(1.05); }
    80% { transform: scale(0.95); }
    100% { transform: scale(1); }
}

.boing-heart {
    animation: boing 0.5s ease;
}

.hearted {
    color: #F7253F;
    margin-top: 10px;
}

.heart {
    margin-top: 10px;
}


.new-text-chapter {
    margin-left:0 !important;
    color: #F7253F !important;
    font-size: 11px !important;
    text-transform: uppercase;
    font-style: normal !important;
    display: inline-block; /* Permet d'appliquer des marges à la classe */
    transform: translateY(-12px) !important;
    padding-left: 5px;
}

.chapter_list ul li {
    position: relative; /* Ajoute une position relative */
}

.cornet {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
}

/* Animation de 0 à 45 degrés */
@keyframes rotateAnimationForward {
    0% {
        transform: rotate(0deg); /* Début de l'animation */
    }
    100% {
        transform: rotate(45deg); /* Fin de l'animation */
    }
}

/* Animation de 45 à 0 degrés */
@keyframes rotateAnimationBackward {
    0% {
        transform: rotate(45deg); /* Début de l'animation */
    }
    100% {
        transform: rotate(0deg); /* Fin de l'animation */
    }
}

.rotate-forward {

    margin-right: 5px;
    animation-name: rotateAnimationForward;
    animation-duration: 0.5s; /* Durée de l'animation */
    animation-timing-function: ease; /* Fonction de temporisation de l'animation */
    animation-fill-mode: forwards; /* Laisser l'élément dans son état final après l'animation */
}

.rotate-backward {
    animation-name: rotateAnimationBackward;
    animation-duration: 0.5s; /* Durée de l'animation */
    animation-timing-function: ease; /* Fonction de temporisation de l'animation */
    animation-fill-mode: forwards; /* Laisser l'élément dans son état final après l'animation */
}


.plus-icon {
    scale: 1.2;
    width: max-content;
    transform-origin: center; /* Définir le point d'ancrage au centre de l'élément */
}

