.top-bar-pc {
    position: absolute;
    left: 0;
    background-color: #181818;
    color: #fff;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 2rem;
    z-index: 999999;
}

.top-bar-content {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-bar-pc-hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.left-part {
    display: flex;
    align-items: center;
}

.right-part {
    display: flex;
    align-items: center;
}


.logo {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.site-icon-text {
    width: 8rem;
    height: auto;
    margin-left: 3vw;
}

.profil-icon {
    height: auto;
    margin: 0 2vw;
    padding-top: 5px;
}

nav ul {
    list-style-type: none;
    display: flex;
}

nav li {
    margin: 0 2rem;
}

nav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 200;
    position: relative;
}

nav a.active {
    color: #fff;
    text-decoration: none; /* Supprimer le soulignement par défaut */
    font-size: 1.1rem;
    position: relative;
}

nav a.active::after {
    content: ''; /* Ajouter un élément pseudo-élément pour créer la ligne */
    position: absolute;
    bottom: -8px; /* Déplacer la ligne plus loin du texte */
    left: 50%; /* Positionner au centre */
    width: 120%;
    height: 2.5px; /* Augmenter l'épaisseur de la ligne */
    background-color: #E73043; /* Couleur de la ligne */
    border-radius: 2px; /* Ajouter des bords arrondis */
    transform: translateX(-50%); /* Centrer la ligne */
}


.search-bar-pc {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    width: 25vw;
    height: 4vh;
}

.search-icon {
    margin-right: 10px;
    margin-left: 5px;
    color: darkgrey;
}

.search-input {
    border: none;
    outline: none;
    flex: 1;
    border-radius: 20px;
    padding: 7px 5px 7px 15px;
    font-size: 16px;    color: #4C4B4B;
}


