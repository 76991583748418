.img-bg-pc {
    background-color: #181818; /* Couleur de fond initiale */
    margin-bottom: 8px;
    width: 8rem;
    height: 8rem;
    border-radius: 11px;
    animation: fadeInOut 3s infinite alternate; /* Animation en boucle de fondu plus clair puis plus foncé */
}

.item-title-pc.progressive-appear,
.item-chapters-pc.progressive-appear {
    opacity: 0; /* Commencez par rendre les éléments transparents */
    animation: fade-in 0.8s ease-in-out forwards; /* Ajoutez une transition pour une apparition en douceur */
}

@keyframes fadeInOut {
    0% {
        background-color: #181818; /* Couleur de fond initiale */
    }
    50% {
        background-color: #AAAAAA; /* Couleur de fond plus claire */
    }
    100% {
        background-color: #181818; /* Couleur de fond initiale */
    }
}

.manga-container-pc {
    width: 100vw;
    margin: auto;
    margin-left:2vw;
}

.manga-item-pc {
    display: inline-block;
    vertical-align: top;
    padding: 0rem .4rem;
}


.item-image-pc {
    width: 8rem;
    height: auto;
    border-radius: 10px;
    opacity: 0; /* Commencez par rendre l'image transparente */
    transition: opacity 0.5s ease-in-out; /* Ajoutez une transition pour une apparition en douceur */
}
.item-image-pc.progressive-appear {
    width: 8rem;
    height: auto;
    border-radius: 10px;
    opacity: 0; /* Commencez par rendre l'image transparente */
    animation: fade-in 0.8s ease-in-out forwards; /* Utilisez une animation CSS pour gérer l'apparition progressive */
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.item-title-pc {
    color: white; /* Couleur du texte */
    margin: 0; /* Aucune marge extérieure */
    overflow: hidden; /* Cache les parties du texte qui dépasse la largeur max */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 6.1rem; /* Largeur maximale pour le titre */
    line-height: 1.3em;
    text-align: left; /* Alignement du texte à gauche */
    direction: ltr;
    text-overflow: ellipsis; /* Ajouter des points de suspension */
    white-space: normal; /* Permettre le retour à la ligne */

}


.item-chapters-pc {
    text-align: left;
    font-weight: normal;
    color: white;
    direction: ltr;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6rem;
    margin: 0;
    margin-top: 4px;
}

.collection-header-pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    margin-top: 1.5em;
}

.text-collection-pc {
    color: #FFF;
    font-weight: 500;
    font-size: 25px;
    margin-left:  5.2vw;

}

.swiper-manga-pc {
    width: 90vw;
    margin-bottom: 3rem;
}

.view-more-btn-pc {
    background-color: rgba(12, 12, 12, 0); /* Couleur de fond du bouton */
    color: white; /* Couleur du texte du bouton */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-arrow-right-pc {
    color: #FFF; /* Couleur de l'icône */
    font-size: 25px; /* Taille de l'icône */
    padding-right: 7vh;
}

/* Styliser l'icône directement si nécessaire */
.icon-arrow-right-pc svg {
    width: 1em; /* Taille de l'icône */
    height: 1em; /* Taille de l'icône */
}

.view-more-btn-pc:hover {
    background-color: #0056b3; /* Couleur de fond du bouton au survol */
}