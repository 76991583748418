.App {
  background: #0C0C0C;

  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: visible;
}
body{

  overflow-x: hidden;
  overflow-y: visible;
}

.center-container {
  height: auto;
}
/* Largeur et couleur de la barre de défilement */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(241, 241, 241, 0.5); /* Opacité à 50% */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Opacité à 50% */
  border-radius: 10px;
}

/* Au survol de la barre de défilement */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.5); /* Opacité à 50% */
}


.mobile-only {
  display: block;
}
.searchResults {
  display: flex;
  flex-direction: column;
}

.searchResults .item-mobile {
  margin-bottom: 25px;
}

.nores {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nores span {
  color: #fff;
  transform: translateY(-60px);
  letter-spacing: 2px;
}

.nores img {
  transform: translate(-25px, -60px);
  margin-bottom: 25px;
  opacity: .5;
}

.search-results-container {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.search-results-container-pc {
  margin-top: 15vh;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
}

.no-results-message-pc {
  color: #fff;
  text-align: center;
  margin-top: 15vh;
}


.searchResults {
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.item-image-search {
  width: 100%;
  height: auto; /* Set height to auto to maintain the image's aspect ratio */
  object-fit: cover;
  border-radius: 10px;
}

/* Style for item text container */
.item-text-search {
  padding: 8px;
}

/* Style for item title */
.item-text-1-search {
  font-size: 14px;
  font-weight: bold;
}

/* Style for item info */
.item-text-2-search {
  font-size: 12px;
  color: #777777;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .search-results-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  .pc-only {
    display: none;
  }
}
/* Cible les écrans dont la largeur est supérieure à 768px (ordinateurs de bureau) */
@media only screen and (min-width: 769px) {
  .mobile-only {
    display: none;
  }
  .pc-only {
    display: flex;
    flex-direction: column; /* Organise les éléments verticalement */
    height: 102vh; /* Utilise toute la hauteur de la fenêtre */
  }
  .top-bar {
    flex: none; /* Empêche la TopBar de redimensionner */
  }
  .main-content {
    display: flex;
    flex: 1; /* Utilise tout l'espace disponible */
    justify-content: space-between; /* Espace uniformément les éléments */
    align-items: center; /* Centrer vert  icalement */
  }
  .left-part-page {
    width: 40%; /* Utilise la moitié de la largeur de la page */
    position: relative;
    left: 5%;
    overflow: hidden;
  }
  .right-part-page {
    width: 50%; /* Utilise la moitié de la largeur de la page */
  }

}

