.gallery-container-mobile {
    width: 90vw;
    margin: auto;
}

.text-all-app-mobile {
    color: #FFF;
    font-weight: bold;
    font-size: 22px;
    margin-left: 8px;
    margin-bottom: 20px;
}

.gallery-mobile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    /* Espace entre les éléments, ajusté pour l'espacement uniforme */
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.item-all-app-mobile {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 8px;
    margin-right: 8px;
}

.item-image-all-gallery-mobile {
    width: 100%;
    height: 5rem;
    width: 5rem;
    border-radius: 20px;
    margin-bottom: 10px;
}

.item-title-mobile,
.item-chapters-mobile {
    font-size: 12px;
    text-align: left;
    color: white;
    margin: 0;
}

.item-title-mobile {
    font-size: 14px;
}

.item-chapters-mobile {
    margin-bottom: 20px;
}