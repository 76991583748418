/* Styles supplémentaires pour swiper si nécessaire */
/* Styles supplémentaires pour swiper si nécessaire */
.swiper {
    width: 100vw;
    margin-bottom: 30px;
}
.slide-mobile {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 22vh; /* Hauteur de votre image */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px;
}

.slide-mobile.progressive-appear {
    opacity: 0; /* Commencez par rendre les éléments transparents */
    animation: fade-in 0.8s ease-in-out forwards; /* Ajoutez une transition pour une apparition en douceur */
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.info-reprise {
    font-size: 13px;
    color: white;
    width: fit-content;
    text-align: left;
    padding-left: 3vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 4vw;
    background-color: rgba(0, 0, 0, 0.62);
    border-bottom-right-radius: 10px;
    /* Ajoutez d'autres styles selon vos préférences */
    margin: 0 !important;
}

.swiper-slide {
    position: relative;
    /* Needed for absolute positioning of content */
    text-align: center;
    font-size: 14px;
}


.slide-mobile::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    border-radius: 8px;
}

.slide-content-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 15px;
}


/* Container pour le texte et l'icône */
.slide-container-mobile {
    align-self: start;
    text-align: left;
    margin-bottom: 40px;
}

/* Styles pour l'icône de l'application */
.icon-app-mobile {
    width: 50px;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 25%;
    object-fit: cover;
    transform: translateY(-40px);
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
}

/* Styles pour le texte au-dessus de l'icône */
.slide-text-above-mobile {
    font-size: 12px;
    /* Smaller text for mobile */
    color: #FFF;
    /* White text color */
    margin-bottom: 2px;
    /* Small space below text */
}

/* Styles pour le texte principal */
.slide-text-mobile {
    font-size: 10px;
    color: #FFF;
}

.download-button-mobile {
    position: absolute;
    bottom: 8px;
    right: 15px;
    padding: 10px 25px;
    background-color: rgba(255, 215, 215, 0.3);
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
}

.carousel-wrapper {
    position: relative;
    height: 27vh;
}

.swiper-container {
    position: relative;
}

.indicators {
    display: flex;
    justify-content: center;
}

.indicator {
    width: 20px;
    height: 4px;
    background-color: #fff;
    margin: 0 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    margin-bottom: 10px;
}

.indicator.active {
    width: 30px;
    background-color: #F7253F;
}


.grayscale {
    opacity: 0.5; /* Opacité pour les images grises */
    transition: opacity 0.4s ease-in-out; /* Transition d'opacité */
}